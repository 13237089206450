$header-height: 90px;
$header-height-mobile: 75px;
$footer-height: 60px;
$navbar-height: 70px;
$nav-link-padding: 10px;

$header-spacing: 100px;
$body-padding-top: 140px;

$color-dark-gray: #231f20;

$text-color: #000;
$input-color: $text-color;

$default-spacing: 20px;

$navbar-default-bg: transparent;
$navbar-default-link-color: #000;
$navbar-default-link-hover-color: #222;

$navbar-inverse-bg: $color-dark-gray;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-hover-color: #fff;

$font-family-sans-serif: 'Pontano Sans', sans-serif;

$flexslider-height: 600px;

$text-condensed-family: 'Flama Condensed', Sans-serif;

$font-size-h1: 23px;
$font-size-h2: 23px;
$font-size-h3: 23px;
$font-size-h4: 23px;
$font-size-h5: 23px;
$font-size-h6: 23px;
$headings-font-family: $text-condensed-family;
$headings-font-weight: 300;

$link-color: #000;
$link-hover-color: lighten($link-color, 25%);
$link-decoration: underline;

$zindex-blocker: 1100;
$zindex-dialog: 2000;

$sliding-menu-text-size: 20px;
$sliding-menu-line-height: 30px;

$brand-success: #a7d2c9;

$panel-border-radius: 	   0px;
$btn-border-radius-base:   0px;
$btn-border-radius-large:  0px;
$btn-border-radius-small:  0px;

$page-max-width: 1920px;