//@import "fonts/flama_condensed";
@import "vars";
//@import "bootstrap";


.page-print {
	padding: $default-spacing;
}

.print-head {
	img {
		max-width: 250px !important;
	}
}

table {
	td {
		padding: 2px;
	}
}

.a-right {
	text-align: right;
}
.a-center {
	text-align: center;
}

.sales-order-print {
	h3 {
		margin: 0px;
	}
}

.col-sm-6 {
	display: inline-block;
	float: none;
	width: 48%;
}

.container {
	margin: 0px !important;
	width: 100% !important;
}